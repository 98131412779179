<template>
  <div class="body">
    练习
    <van-nav-bar left-text="返回" left-arrow @click-left="onpage">
      <template #right>
        <van-button class="btn" type="warning" @click="complete">完成评估</van-button>
      </template>
    </van-nav-bar>
    <div class="from">
      <div class="mycard" v-if="mycard">
        <van-button class="pingubtn" type="danger">{{ tagname }}</van-button>
        <div class="text">{{ coppydata.title }}</div>
      </div>
      <div class="starting" v-if="btnshow" @click="twodata(i)">开始答题</div>
    </div>
    <div class="scorebtn" @click="scoreshow">
      <div class="trangle"></div>
      <div class="box"></div>
      <p>分数</p>
    </div>
    <div class="fixedcard">
      <div class="buttongroup" v-if="this.num > 1" @click="ona">
        <div style="border: 1px solid #ff8a88">上一题</div>
      </div>
      <!-- <div class="item">
        <span class="iconfont icon-ICON myicon" @click="opentable"></span
        ><span>项目记录(总数4个)</span>
      </div>-->
      <div class="score">
        <span
          v-for="(item, index) in score"
          :key="index"
          :class="{ active: currentIndex === index }"
          @click="getindex(index)"
        >{{ item }}</span>
      </div>
      <div class="buttongroup">
        <div
          v-if="text === '手动'"
          style="background: #eea837; color: #fff"
          @click="manual('手动')"
        >手动打分</div>
        <div
          v-if="text === '自动'"
          style="background: #eea837; color: #fff"
          @click="manual('自动')"
        >恢复自动</div>
        <div style="border: 1px solid #ff8a88" @click="next">下一题</div>
      </div>
    </div>
    <!-- 分数弹框 -->
    <van-popup class="scorebox" v-model="show">
      <div class="calculatescore">
        <p>1M</p>
        <ul>
          <li>0分</li>
          <li>0.5分</li>
          <li>1分</li>
          <li>1.5分</li>
        </ul>
      </div>
      <div class="calculatescore">
        <p>2M</p>
        <ul>
          <li>0分</li>
          <li>0.5分</li>
          <li>1分</li>
          <li>1.5分</li>
        </ul>
      </div>
      <div class="calculatescore">
        <p>3M</p>
        <ul>
          <li>0分</li>
          <li>0.5分</li>
          <li>1分</li>
          <li>1.5分</li>
        </ul>
      </div>
      <div class="calculatescore">
        <p>10M</p>
        <ul>
          <li>0分</li>
          <li>0.5分</li>
          <li>1分</li>
          <li>1.5分</li>
        </ul>
      </div>
      <div class="calculatescore">
        <p>2M</p>
        <ul>
          <li>0分</li>
          <li>0.5分</li>
          <li>1分</li>
          <li>1.5分</li>
        </ul>
      </div>
      <div class="calculatescore">
        <p>3M</p>
        <ul>
          <li>0分</li>
          <li>0.5分</li>
          <li>1分</li>
          <li>1.5分</li>
        </ul>
      </div>
      <div class="calculatescore">
        <p>1M</p>
        <ul>
          <li>0分</li>
          <li>0.5分</li>
          <li>1分</li>
          <li>1.5分</li>
        </ul>
      </div>
      <div class="calculatescore">
        <p>2M</p>
        <ul>
          <li>0分</li>
          <li>0.5分</li>
          <li>1分</li>
          <li>1.5分</li>
        </ul>
      </div>
      <div class="calculatescore">
        <p>3M</p>
        <ul>
          <li>0分</li>
          <li>0.5分</li>
          <li>1分</li>
          <li>1.5分</li>
        </ul>
      </div>
      <div class="calculatescore">
        <p>1M</p>
        <ul>
          <li>0分</li>
          <li>0.5分</li>
          <li>1分</li>
          <li>1.5分</li>
        </ul>
      </div>
      <div class="calculatescore">
        <p>2M</p>
        <ul>
          <li>0分</li>
          <li>0.5分</li>
          <li>1分</li>
          <li>1.5分</li>
        </ul>
      </div>
      <div class="calculatescore">
        <p>3M</p>
        <ul>
          <li>0分</li>
          <li>0.5分</li>
          <li>1分</li>
          <li>1.5分</li>
        </ul>
      </div>
      <div class="calculatescore">
        <p>1M</p>
        <ul>
          <li>0分</li>
          <li>0.5分</li>
          <li>1分</li>
          <li>1.5分</li>
        </ul>
      </div>
      <div class="calculatescore">
        <p>2M</p>
        <ul>
          <li>0分</li>
          <li>0.5分</li>
          <li>1分</li>
          <li>1.5分</li>
        </ul>
      </div>
      <div class="calculatescore">
        <p>3M</p>
        <ul>
          <li>0分</li>
          <li>0.5分</li>
          <li>1分</li>
          <li>1.5分</li>
        </ul>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Notify } from "vant";
import "../../../plugin/element";
import {
  getuid,
  getreviewid,
  gettopicid,
  getltemname,
} from "/src/api/userdata.js";
import { getexercises, gettablelist } from "../../../api/index";
export default {
  data() {
    return {
      num: 1,
      show: false,
      text: "手动",
      tableshow: false,
      score: ["0分", "0.5分", "1分"],
      currentIndex: "",
      //练习题的数据
      btnshow: false,
      coppydata: [],
      tagname: "",
      idarr: {
        uid: "",
        reviewid: "",
        mainid: "",
      },
      number: "",
      i: 0,
      mycard: false,
      exercisesdata: [],
    };
  },

  // props: ["exercisesdata","getalldata"],
  mounted() {
    this.getlist();
    // this.getdata();
  },

  methods: {
    getlist() {
      const uid = getuid();
      const reviewid = getreviewid();
      const mainid = gettopicid();
      this.name = getltemname();
      gettablelist(uid, reviewid, mainid).then((res) => {
        this.exercisesdata = res.data;
        this.coppydata = this.exercisesdata;
        console.log(this.coppydata, "this.coppydata")
        this.twodata(0)
      });
    },
    twodata(index) {
      const id = this.coppydata.pract_ids[index].id;
      getexercises(id).then((res) => {
        if (res.code === 1) {
          this.coppydata.title = res.data.title;
          console.log(res, "这是详情")
        } else {
          Notify(res.msg);
        }
      });
    },
    getdata() {
      this.uid = getuid();
      this.reviewid = getreviewid();
      this.mainid = gettopicid();
      this.coppydata = this.exercisesdata;
      console.log(this.coppydata, " this.coppydata");
      if (this.coppydata.pract_ids.length > 1) {
        this.btnshow = true;
        this.number = this.coppydata.pract_ids.length;
        this.twodata(0);
      } else {
        this.mycard = true;
        this.twodata(0);
        this.btnshow = false;
      }
      this.tagname = getltemname();
      console.log(this.coppydata, "这是练习题的数据");
    },



    next() {
      this.num += 1;
    },
    getindex(val) {
      this.currentIndex = val;
    },
    onpage() {
      this.$router.replace({
        name: "startpingu",
        query: {
          ass_num: this.$route.query.ass_num,
          eva_type: this.$route.query.eva_type
        }
      }).then(() => {
        this.$router.go(-1)
      });
    },
    complete() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "请点击开始答题按钮",
          confirmButtonColor: "#FA4B52",
          messageAlign: "center",
          confirmButtonText: "继续评估",
          cancelButtonText: "手动调整",
        })
        .then(() => {
          // on confirm
        })
        .catch(() => {
          alert(1);
          this.$router.replace({
            name: "startpingu",
            query: {
              ass_num: this.$route.query.ass_num,
              eva_type: this.$route.query.eva_type
            }
          }).then(() => {
            this.$router.go(-1)
          });
        });
    },
    ona() {
      this.num -= 1;
      if (this.num === 0) {
        this.num = 0;
      }
    },
    scoreshow() {
      this.show = true;
    },
    manual(val) {
      console.log(val);
      if (val === "手动") {
        this.text = "自动";
      } else {
        this.text = "手动";
      }
    },
    deltablemodel() {
      this.tableshow = false;
    },
    opentable() {
      this.tableshow = !this.tableshow;
    },
    deleteitem() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "删除项目记录后会影响本题得分，确定删除吗？",
          confirmButtonColor: "red",
          messageAlign: "left",
        })
        .then(() => {
          alert("确定");
          // on confirm
        })
        .catch(() => {
          alert("取消");
          // on cancel
        });
    },
  },
};
</script>

<style lang="less" scoped>
</style>
 